<template>
  <div id="reserve-generic" class="card">
    <img
        v-lazy="astroImg + '?h=30'"
        height="30px"
        width="30px"
        alt="tulum party support"
        class="d-inline-lg "
        style="
                    margin-left: auto;
                    margin-right: auto;
                    left: 0;
                    right: 0;
                    margin-top: 20px;
                  "
    />
    <br />
    <p>Secure your spot at <strong><br>{{ club.title }}</strong></p>
    <br />
    <RsvpViaDialog
        text="INFO & RSVP"
        :club-config="this.rsvpConfig">
    </RsvpViaDialog>
    <br />
    <small
    >We operate from 9AM - 11PM / Answer within 15 mins</small
    >
    <br>
    <br>
    <br>
  </div>
</template>
<script>
export default {
  name: 'ReservationsCard',
  props: {
    astroImg: {},
    club: {}
  }
};
</script>

<template>
  <div
    style="padding: 10px"
    v-if="!noUpcomingEvents"
    :style="{ background: `linear-gradient(${this.color}, black)` }"
  >
    <div id="card-title" class="text-left" v-if="showCardTitle">

      <h4 class="section-header text-left">
        <img
          v-lazy="this.logo + '?w=40&h=40&fit=clamp'"
          :alt="incomingParty.title"
          height="40px"
          width="40px"
          style="border-radius: 300px"
        />
        {{ incomingParty.title.split("@")[0] }}
      </h4>
      <p
        style="
        margin-left: 55px;
        font-size: 0.7rem;
        margin-top: -35px;
        text-transform: uppercase;
      "
      >
        {{ this.nextEventDate }}
      </p>
    </div>


    <div class="centered card" style="margin: 10px">
      <h3 v-if="incomingParty.title" style="margin-top: 20px">
        {{ incomingParty.title.split('|')[0] }}
      </h3>
      <EventCard
        :with-content="incomingParty.metadata.magicEvent"
        :withLineup="false"
        :with-link="false"
        :with-title="false"
        v-bind:event="incomingParty"
      >
      </EventCard>
      <ReservationsCard club="{name: 'lala'}"></ReservationsCard>
    </div>
  </div>
</template>
<script>
import EventCard from '@/components/EventCard/EventCard';
import moment from 'moment';
import ReservationsCard from '@/pages/Venues/ReservationsCard';

export default {
  name: 'incomingMiniParty',
  components: { ReservationsCard, EventCard },
  computed: {
    noUpcomingEvents: {
      get() {
        return !this.$store.state.status.success && !this.incomingParty;
      }
    },
    nextEventDate: {
      get() {
        moment();
        return (
          this.incomingParty &&
          moment(this.incomingParty.metadata.date).calendar(null, {
            sameDay: '[Today]',
            nextDay: '[Tomorrow]',
            nextWeek: 'dddd',
            sameElse: 'DD/MM/YYYY'
          })
        );
      }
    }
  },
  props: {
    cardTitle: {
      type: String,
      default: 'Next Event'
    },
    showCardTitle: {
      type: Boolean,
      default: true,
    },
    logo: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: ''
    },
    incomingParty: {
      type: Object,
      default: () => {
        return {title: "loading", metadata: { main_image: {image_url: ''}}}
      }
    }
  }
};
</script>
